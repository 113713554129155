<template>
  <div class="bg-grey">
    <b-container fluid>
      <b-row>
        <b-col class="col-12 search-box mt-5 pt-5 pb-3 px-3">
          <b-form inline class="rounded-lg bg-white py-2">
            <b-input-group class="border-right col-md-5">
              <span class="input-group-text"><i class="fa fa-search"></i></span>
              <b-input placeholder="Search for jobs"></b-input>
            </b-input-group>
            <b-input-group class="col-md-2">
              <span class="input-group-text">
                <img src="@/assets/app-icons/location.svg" width="15px" />
              </span>
              <select class="form-control">
                <option>LOCATION</option>
              </select>
            </b-input-group>
            <!-- offset-md-3 col-md-2 -->
            <div class="ml-auto">
              <b-button class="btn btn-primary btn-custom__one btn-center py-md-2 px-4 mr-3">SEARCH</b-button>
            </div>
          </b-form>
        </b-col>

        <b-col class="col-12 flex-between__center pb-3">
          <h6 class="mb-0 font-weight-bold">126 jobs applied</h6>
          <div class="flex-between__center">
            <div>
              <h6 class="fs-14 mb-0">
                <img src="@/assets/app-icons/sort-amount-down-alt-solid.svg" alt="" height="16px" width="16px" />
                Sort By
              </h6>
            </div>
            <div class="pl-3">
              <select class="form-control">
                <option>Default</option>
              </select>
            </div>
          </div>
        </b-col>

        <b-col class="col-12">
          <div class="d-sm-none filter-box display-3">
            <b-icon icon="funnel-fill" @click="showFilters" class="p-3 f-primary"></b-icon>
          </div>
          <b-row>
            <b-col class="col-md-3">
              <div class="col-filter py-3" :class="{ isMobileFilter }">
                <div class="text-right d-sm-none">
                  <svg @click="hideFilters" width="3rem" height="3rem" viewBox="0 0 16 16" class="text-body bi bi-x"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
                <div class="pb-1 mb-2 px-2">
                  <h4 class="mb-0 fpn-b">Filters</h4>
                </div>
                <div v-for="(data, index) in filter_titles" :key="index">
                  <div class="border-bottom border-top py-2 px-2 collapse-card">
                    <h6 class="filter-tab-title" v-b-toggle="'collapse-' + index">
                      {{ data }}
                    </h6>
                    <b-collapse :id="'collapse-' + index">
                      <div class="flex-between mb-1">
                        <span class="filter-tab-text">India (28)</span>
                        <b-form-checkbox value="India"> </b-form-checkbox>
                      </div>
                      <div class="custom-flex">
                        <span class="filter-tab-text">USA (42)</span>
                        <b-form-checkbox value="USA"> </b-form-checkbox>
                      </div>
                    </b-collapse>
                  </div>
                </div>

                <div class="flex-between px-4 py-3">
                  <button type="button" class="btn-light--black btn-custom__one px-3 py-0 rounded-lg">RESET</button>
                  <button type="button" class="btn-primary btn-custom__one px-4 py-2 rounded-lg">APPLY</button>
                </div>
              </div>
            </b-col><!--End Col-Filter-->

            <b-col class="col-12 col-sm-12 col-md-9">
              <div class="bg-white rounded-lg box-shadow p-3 mb-3" v-for="(job, index) in 10" :key="index">
                <b-row class="pb-3 border-bottom">
                  <b-col class="col-md-6">
                    <div class="d-flex">
                      <img src="../../assets/pp.jpg" class="rounded" height="100px" />
                      <div class="pl-3">
                        <h4 class="fw-bold">Ren Delan</h4>
                        <div class="pt-2">
                          <h6 class="f-light">35, Product</h6>
                          <h6 class="f-light">Designer, Google</h6>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="col-md-6 d-flex justify-content-between align-items-center">
                    <div>
                      <button class="btn btn-success">97% Match &nbsp;<img
                          src="@/assets/app-icons/question-mark.svg" /></button>
                    </div>
                    <div>
                      <h5 class="fs-13 fw-bold">PLAY VIDEO</h5>
                    </div>
                    <div>
                      <h5 class="fs-13 fw-bold">VIEW CV</h5>
                    </div>
                  </b-col>
                </b-row>
                <div class="flex-between border-bottom py-3">
                  <div>
                    <h6 class="f-light">Skills</h6>
                    <label style="color: black;" class="label-grey">CSS</label>
                    <label style="color: black;" class="label-grey ml-2">HTML</label>
                    <label style="color: black;" class="label-grey ml-2">CSS</label>
                    <label style="color: black;" class="label-grey ml-2">CSS</label>
                    <label style="color: black;" class="label-grey ml-2">Project Management</label>
                  </div>
                </div>
                <b-row class="pt-3">
                  <b-col class="col-md-12">
                    <div class="flex-between align-items-center">
                      <div class="flex-between">
                        <div class="pr-3">
                          <h5 class="fs-14">Shortlist</h5>
                        </div>
                        <div class="pr-3">
                          <h5 class="fs-14">Message</h5>
                        </div>
                        <div class="pr-3">
                          <h5 class="fs-14">Forward</h5>
                        </div>
                      </div>
                      <button class="btn btn-primary btn-custom__one">VIEW FULL PROFILE</button>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ApplicantJobService from "@/apiServices/ApplicantJobServices";
import ApplyJobService from "@/apiServices/ApplyJobServices";
import JobAppliedSuccess from "@/views/Job-applied-successfully.vue";

export default {
  components: { JobAppliedSuccess },
  data() {
    return {
      jobs: [{}],
      appliedJob: {
        user_id: null,
        job_id: null,
      },
      pagination: {
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
        searchText: "",
        feature: "Y",
      },
      isMobileFilter: window.innerWidth <= 700,
      selected: [],
      filter_titles: ["Country", "City", "Area", "Job Role", "Speciality", "Career level", "Employment type", "Gender", "Company Type", "Date Modified"],
    };
  },
  created() {
    addEventListener("resize", () => {
      this.isMobileFilter = innerWidth <= 700;
    });
  },
  beforeMount() {
    console.log("this.pagination :>> ", this.pagination);
    this.getAllJobs(this.pagination);
  },
  methods: {
    getAllJobs(payload) {
      ApplicantJobService.GetAllJobs(payload).then((res) => {

        this.jobs = res.data.data;
      });
    },
    applyJob(job_id) {
      const user_id = this.$cookies.get("user_id");
      console.log("user_id, job_id :>> ", user_id, job_id);
      const payload = { user_id: user_id, job_id: job_id };
      ApplyJobService.ApplyJob(payload)
        .then((res) => {

          // this.$bvModal.show("successPage");
          this.$toasted.show("Job Applied Successfully!", {
            position: "top-right",
            duration: 6000,
            icon: "check",
          });
          //this.$router.push({ name: "Job-applied-successfully" });
        })
        .catch((err) => {

          this.$toasted.show("You have already applied for this job", {
            position: "top-right",
            duration: 6000,
            icon: "clear",
          });
        });
    },
    showFilters: function () {
      document.querySelector(".isMobileFilter").classList.add("show-filter");
      document.querySelector(".navbar").classList.add("navbar-none");
    },
    hideFilters: function () {
      document.querySelector(".isMobileFilter").classList.remove("show-filter");
      document.querySelector(".navbar").classList.remove("navbar-none");
    },
  },
};
</script>

<style>
.job-modal .modal-footer div {
  display: flex;
}

.job-modal .modal-footer {
  justify-content: center;
}

.job-modal .modal-header>div:first-child {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
